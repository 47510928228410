<template>
  <el-dialog :title="type == 'create' ? '添加角色' : '配置菜单'" :visible.sync="visible" :close-on-click-modal="false" width="550px">
    <el-form ref="formRef" :model="formData" :rules="formRule" label-width="100px">
      <el-form-item label="角色名称：" prop="roleName">
        <el-input v-model="formData.roleName" :disabled="type === 'edit'" maxlength="16" placeholder="请输入角色名称，最多16个字" />
      </el-form-item>
      <el-form-item label="角色说明：" prop="roleInfo">
        <el-input v-model="formData.roleInfo" maxlength="50" placeholder="请输入角色说明，最多50个字" />
      </el-form-item>
      <el-form-item label="配置菜单：" required />
      <el-form-item label-width="20px">
        <div class="menuTreeBox">
          <el-tree
            v-loading="treeLoading"
            ref="tree"
            default-expand-all
            :data="TreeData"
            :props="props"
            :node-key="props.value"
            @check-change="getCheckedNodes"
            :default-checked-keys="treeListIds"
            show-checkbox
          >
          </el-tree>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import _api from "@/utils/request";
export default {
  name: 'RoleDia',
  props: {
    marketerTerminalCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      row: {},
      type: 'create',

      formData: {
        roleName: '',
        roleInfo: '',
      },
      formRule: {
        roleName: [{ required: true, message: '请输入角色名称', trigger: 'change' }],
      },
      
      treeLoading: false,
      TreeData: [],
      treeIds: [],
      treeListIds:[],
      props: {
        value: 'id',
        children: 'children',
        label: 'resourceName'
      },
    }
  },
  methods: {
    async open(row) {
      await this.getTree()
      this.row = row
      if (row) {
        this.type = 'edit'
        this.getInfo()
      } else {
        this.treeIds = []
        this.treeListIds = []
        this.type = 'create'
      }
      this.$nextTick(() => {
        this.visible = true
      })
    },
    getInfo() {
      this.treeLoading = true
      _api.marketerRoleInfo({ id: this.row.id }).then(res => {
        const { marketerRole, selectedRes } = res.data
        this.formData.roleName = marketerRole.roleName
        this.formData.roleInfo = marketerRole.roleInfo
        this.$refs['tree'].setCheckedKeys(selectedRes.map(item => item.id))
      }).finally(() => {
        this.treeLoading = false
      })
    },
    getTree() {
      this.treeLoading = true
      return _api.marketerRoleAllRes({marketerTerminalCode:this.marketerTerminalCode}).then(res => {
        this.TreeData = res.data
        this.treeLoading = false
      })
    },
    // tree点击
    getCheckedNodes () {
      let res = this.$refs.tree.getCheckedKeys()
      let result = this.$refs.tree.getHalfCheckedKeys()
      this.treeIds = res.concat(result)
    },
    close() {
      this.visible = false
    },
    confirm() {
      if (!this.treeIds.length) return this.$message.error('至少选择一个菜单')
      this.$refs['formRef'].validate((vaild) => {
        if (vaild) {
          _api.marketerRoleSave({
            ...this.formData,
            id: this.row?.id,
            arr: this.treeIds,
            marketerTerminalCode:this.marketerTerminalCode
          }).then(() => {
            this.$message.success('操作成功！')
            this.$emit('confirm', this.type)
            this.close()
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.menuTreeBox{
  border: 1px solid #0981FF;
  overflow: hidden;
  border-radius: 10px;
}
</style>