<template>
  <div class="GlobalFormDemo">
    <GardenSlwct
      style="margin-bottom: 20px;"
      :tab-data="tabData"
      :activeIndex="tabActiveIndex"
      width="120px"
      border-radius="40px"
      @tabAction="tabAction"
    />
    <el-form :model="formData" inline label-width="80px" size="small" class="el-form-group_isRound">
      <el-form-item label="角色名称" prop="roleId">
        <el-select v-model="formData.roleId" filterable clearable placeholder="请输入角色名称查询">
          <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" :loading="searchLoading" round @click="handleConfirm">查询</el-button>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="$refs['roleDia'].open()">添加角色</el-button>
      </el-form-item>
    </el-form>
    <RoleDia ref="roleDia" :marketerTerminalCode="formData.marketerTerminalCode" @confirm="handleConfirm()" />
  </div>
</template>

<script>
import moment from "moment";
import _api from "@/utils/request";
import RoleDia from './dialog/RoleDia.vue';
import GardenSlwct from "@/components/common/gardenSlwct.vue";
export default {
  components: { RoleDia,GardenSlwct },
  name: 'Form',
  data() {
    return {
      formData: {
        roleId: '',
        marketerTerminalCode: ''
      },
      allRoleList: [],
      searchLoading: false,
      tabActiveIndex: 0,
      tabData: [],
      roleList: [],
    }
  },
  created(){
    this.getMarketTerminalList()
  },
  watch:{
    'formData.marketerTerminalCode': {
      handler(newVal, oldVal) {
        if(this.allRoleList && this.allRoleList.length){
          this.roleList = this.allRoleList.filter(ele => ele.marketerTerminalCode === newVal);
        }else if(newVal){
          this.getRoleList(newVal)
        }
      },
    }
  },
  methods: {
    getRoleList(code) {
      _api.marketerRoleSelectList().then(res => {
        this.allRoleList = res.data
        if(code){
          this.roleList = this.allRoleList.filter(ele => ele.marketerTerminalCode === code);
        }
      })
    },
    //查询
    handleConfirm() {
      let _this = this
      this.searchLoading = true
      console.log(this.formData,'this.formDatathis.formData');
      
      const SeachParams = {
        ...this.formData,
        disuseLoding: () => {
          if (typeof _this.stopSearchLoading === 'function') {
            _this.stopSearchLoading()
          }
        },
      } 
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    },
    stopSearchLoading() {
      this.searchLoading = false
    },
    tabAction(val) {
      this.tabActiveIndex = val
      this.formData.marketerTerminalCode = this.tabData[val].value
      this.handleConfirm()
    },
    getMarketTerminalList() {
      _api.marketTerminalList().then(res => {
        if (res.code === 1) {
          this.$store.commit("tagsView/SET_TERMINALLIST", res.data);
          this.tabData = res.data.map(item => {
            return {
              text: item.marketerTerminalName,
              value: item.marketerTerminalCode,
              hideNum: true
            }
          })
          this.formData.marketerTerminalCode = this.tabData[0].value
          this.handleConfirm()
        }
      })
    }
  }
}
</script>
