<template>
  <el-dialog
    title="管理商家(门店运营商)"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="1300px"
    top="50px"
    @close="btnStoreClose"
  >
    <GlobalForm
      v-if="showForm"
      :form-item-list="formItemList"
      :inline="true"
      round
      confirmBtnName="查询"
      @handleConfirm="search"
    />
    <div style="display: flex; justify-content: space-between">
      <el-card style="flex-grow:1">
        <div style="font-weight: bold; height: 40px">
          选择门店商家
          <span style="color:#ccc; font-size: 12px">系统已过滤矩阵映射门店商</span>
        </div>
        <GlobalTable
          ref="leftTable"
          v-loading="leftLoading"
          :columns="tableColumns"
          :data="leftList"
          :currentPage="leftResult.current"
          :total="leftResult.total"
          :pageSize="pageSize"
          :max-height="500"
          isSelection
          @handleSelectionChange="(v) => (leftSelectList = v)"
          @handleCurrentChange="getLeftData"
          >
          <el-table-column label="当前运营人员" slot="marketerNames" align="center">
            <template slot-scope="{ row }">
              {{ row.marketerNames || '--' }}
            </template>
          </el-table-column>
        </GlobalTable>
      </el-card>
      <div class="arrows-box">
        <div
          :disabled="!rightSelectList.length"
          class="arrow-btn left"
          @click="rightSelectList.length && move('left')"
        >移除</div>
        <div
          :disabled="!leftSelectList.length"
          class="arrow-btn right"
          @click="leftSelectList.length && move('right')"
        >保存</div>
      </div>
      <el-card style="flex-grow:1">
        <div style="font-weight: bold; height: 40px">
          已添加商家（{{ addNum }}个）
        </div>
        <GlobalTable
          ref="rightTable"
          v-loading="rightLoading"
          :columns="rightTableColumns"
          :data="rightList"
          :currentPage="rightResult.current"
          :total="rightResult.total"
          :pageSize="pageSize"
          :max-height="500"
          isSelection
          @handleSelectionChange="(v) => (rightSelectList = v)"
          @handleCurrentChange="getRightData"
          ></GlobalTable>
      </el-card>
    </div>
    <div slot="footer">
      <!-- <el-button size="small" @click="btnStoreClose">关闭</el-button>
      <el-button size="small" type="primary" @click="confirm">确认添加</el-button> -->
    </div>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
import { confirm } from '@/components/common/messagebox'
export default {
  name: "CompanySelectDia",
  data() {
    return {
      dialogVisible: false,
      row: {},
      // 搜索条件相关
      showForm: false,
      filterData: {},
      formItemList: [
        {
          labelName: "商家名称",
          key: "companyId",
          type: "selectFilterable",
          placeholder: "请输入商家名称查询",
          clear: true,
          option: [],
          clearFun: ()=>{}
        },
      ],
      // Table数据相关，list展示的数据，SelectList选中的列表，Result接口返回
      rightList: [],
      leftList: [],

      leftSelectList: [],
      rightSelectList: [],

      leftLoading: false,
      rightLoading: false,

      leftResult: {
        total: 0,
        current: 1
      },
      rightResult: {},

      pageSize: 10,
      leftTotal: 0,
      rightTotal: 0,
      tableColumns: [
        { label: '商家名称', prop: "companyName" },
        { label: '联系人', prop: "companyContact" },
        { label: '联系电话', prop: "companyPhone" },
        { label: '门店数量', prop: "storeNum" },
        { slotName: "marketerNames" },
      ],
      rightTableColumns: [
        { label: '商家名称', prop: "companyName" },
        { label: '联系人', prop: "companyContact" },
        { label: '联系电话', prop: "companyPhone" },
      ],

      // 选中的id列表
      idList: [],
      addNum: 0
    };
  },
  created() {
    // this.getClassList()
    // this.getResidentList()
  },
  methods: {
    open(row) {
      this.getClassList()
      this.row = row
      // 清空筛选
      this.filterData = {}
      this.showForm = true
      this.dialogVisible = true;
      this.getData()
    },
    getData() {
      this.getLeftData()
      this.getRightData()
    },
    getLeftData(pageNum = 1) {
      this.leftLoading = true
      _api.marketerUserCompanyList({ 
        ...this.filterData,
        marketerId: this.row.id,
        type: '00',
        pageNum,
        pageSize: this.pageSize
      }).then(res => {
        this.leftResult = res.data.ipage
        this.leftList = res.data.ipage.records || []
      }).finally(() => {
        this.leftLoading = false
      })
    },
    getRightData(pageNum = 1) {
      this.rightLoading = true
      _api.marketerUserCompanyList({
        ...this.filterData,
        marketerId: this.row.id,
        type: '01',
        pageNum,
        pageSize: this.pageSize
      }).then(res => {
        this.rightResult = res.data.ipage
        this.rightList = res.data.ipage.records || []
        this.addNum = res.data.addNum
      }).finally(() => {
        this.rightLoading = false
      })
    },
    // 获取分类集合
    getClassList() {
      _api.getrchantSelectList().then(res => {
        const list = res.data.map(item => ({
          value: item.id,
          label: item.name
        }))
        this.formItemList[0].option = list
      })
    },
    // 获取旧机品牌列表
    // getResidentList() {
    //   _api.ResidentList().then(res => {
    //     const list = res.data.map(item => ({
    //       value: item.id,
    //       label: item.name
    //     }))
    //     this.formItemList[1].option = list
    //   })
    // },
    search(v) {
      this.filterData = v
      this.getData()
    },
    confirm() {
      this.$emit(
        "confirm",
        JSON.parse(JSON.stringify(this.idList))
      );
      this.btnStoreClose();
    },
    btnStoreClose() {
      this.dialogVisible = false;
      this.showForm = false
      this.$emit('close')
    },
    move(type) { // 数据移动
      confirm({
        title: '操作提示',
        message: type === 'left' ? '是否确认移除选择商家？' : '是否确认保存选择商家？',
        confirm: () => {
          
          const selectList = type === "left" ? this.rightSelectList : this.leftSelectList;
          const companyIds = selectList.map((item) => item.companyId);
          _api.marketerUserCompanySave({
            companyIds,
            marketerId: this.row.id,
            type: type === 'left' ? '00' : '01'
          }).then(() => {
            this.getData()
            this.$message.success('操作成功')
          })
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.arrows-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  .arrow-btn {
    // width: 40px;
    // height: 40px;
    // box-sizing: border-box;
    // border-radius: 6px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // cursor: pointer;
    // box-shadow: 2px 6px 6px 0px rgba(102, 102, 102, 0.16);

    width: 60px;
    height: 30px;
    box-sizing: border-box;
    // background: rgba(255,104,123,0.06);
    border-radius: 15px;
    // border: 1px solid #FF687B;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    &[disabled] {
      cursor: not-allowed;
      background: rgba(181,181,181,0.24) !important;
      border: 1px solid #B5B5B5 !important;
      color: #999999 !important;
      &.left::before {
        background: url("https://img.fanxinghuishou.cn/20240603/501748200.png");
      }
      &.right::after {
        background: url("https://img.fanxinghuishou.cn/20240603/635302437.png");
      }
    }
    &.left {
      background: rgba(255,104,123,0.06);
      border: 1px solid #FF687B;
      color: #FF687B;
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        margin-right: 3px;
        background: url('https://img.fanxinghuishou.cn/20240603/641040858.png');
      }
    }
    &.right {
      margin-top: 13px;
      background: rgba(86,139,255,0.08);
      border: 1px solid #568BFF;
      color: #568BFF;
      &::after {
        content: '';
        width: 8px;
        height: 8px;
        margin-left: 3px;
        background: url('https://img.fanxinghuishou.cn/20240603/607681012.png');
      }
    }
  }
}
</style>