<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="所属部门" slot="section" align="center">
        <template slot-scope="{ row }">
          <div v-for="item in row.marketerDepartmentInfo" :key="item.terminalCode">{{ item.terminalName }}</div>
        </template>
      </el-table-column>
      <el-table-column label="角色" slot="roleName" align="center">
        <template slot-scope="{ row }">
          <div v-for="item in row.marketerDepartmentInfo" :key="item.terminalCode">{{ item.roleName }}（{{item.terminalName}}）</div>
        </template>
      </el-table-column>
      <el-table-column label="运营门店商" slot="companyNum" align="center">
        <template slot-scope="{ row }">
          <el-link type="primary" :underline="false">{{row.companyNum}}</el-link>
          <p><el-link type="warning" @click="$refs['companySelectDia'].open(row)">添加</el-link></p>
        </template>
      </el-table-column>
      <el-table-column label="运营回收商" slot="merchantNum" align="center">
        <template slot-scope="{ row }">
          <el-link type="primary" :underline="false">{{row.merchantNum || 0}}</el-link>
          <p><el-link type="warning" @click="$refs['merchantSelectDia'].open(row)">添加</el-link></p>
        </template>
      </el-table-column>
      <el-table-column label="创建人" slot="createAdminAccount" align="center">
        <template slot-scope="{ row }">
          {{row.createAdminName}}-{{row.createAdminAccount}}
        </template>
      </el-table-column>
      <el-table-column label="最近登录时间" slot="lastLoginTime" align="center">
        <template slot-scope="{ row }">
          {{row.lastLoginTime || '--'}}
        </template>
      </el-table-column>
      <el-table-column label="动态口令登录码" slot="code" align="center">
        <template slot-scope="{row}">
          <el-link type="primary" @click="$refs['codeDia'].open(row)">生成</el-link>
        </template>
      </el-table-column>
      <el-table-column label="启用/禁用" slot="isEnable" align="center">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.isEnable"
            inactive-color="#bebebe"
            @change="(v) => handleSwitch(v, row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        slot="operation"
        align="center"
        min-width="110px"
      >
        <template slot-scope="{ row }">
          <el-button size="mini" type="danger" round @click="handleReset(row)">重置密码</el-button>
          <el-button size="mini" type="primary" round @click="$refs['accountDia'].open(row.id)">编辑</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <CodeDia ref="codeDia" />
    <CompanySelectDia ref="companySelectDia" @close="handleCurrentChange(pageNum)" />
    <AccountDia type="edit" ref="accountDia" @confirm="handleCurrentChange(pageNum)" />
    <MerchantSelectDia ref="merchantSelectDia" @close="handleCurrentChange(pageNum)" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import { confirm } from '@/components/common/messagebox'
import CodeDia from './dialog/CodeDia.vue';
import CompanySelectDia from './dialog/CompanySelectDia.vue';
import AccountDia from './dialog/AccountDia.vue';
import MerchantSelectDia from "./dialog/MerchantSelectDia.vue";
export default {
  name: "Table",
  components: {CodeDia, CompanySelectDia, AccountDia,MerchantSelectDia},
  data() {
    return {
      loading: false,
      seachDataList: [],
      page:{
        pageNum:1,
        pageSize:10,
        total: 0,
      },
      pageNum:1,
      tableColumns: [
        { label: "人员姓名", prop: "userName" },
        { slotName: "section" },
        { slotName: "roleName" },
        { label: "手机号", prop: "userPhone" },
        { label: "登录账号", prop: "userAccount" },
        { slotName: "companyNum" },
        { slotName: "merchantNum" },
        { slotName: "createAdminAccount" },
        { label: "创建时间", prop: "createTime" },
        { slotName: "lastLoginTime" },
        { slotName: "code" },
        { slotName: "isEnable" },
        { slotName: 'operation' },
      ],
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  watch:{
    SeachParams(newVal){
      console.log(newVal)
      this.page.pageNum = 1;
      this.handleCurrentChange()
    },
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      }
      const baseRequest = {
        marketerTerminalCode: this.SeachParams.marketerTerminalCode || '',
        companyId: this.SeachParams.companyId || '',
        merchantId: this.SeachParams.merchantId || '',
        marketerId: this.SeachParams.marketerId || '',
        roleId: this.SeachParams.roleId || '',
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.marketerUserList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    handleSwitch(v, row) {
      confirm({
        title: '启用/禁用账号',
        message: '确认要执行该操作吗？',
        confirm: () => {
          _api.marketerUserOc({ marketerId: row.id, isEnable: v }).then(res => {
            this.$message.success('操作成功！')
          })
        },
        closed: () => {
          this.handleCurrentChange(this.pageNum)
        }
      })
    },
    handleReset(row) {
      confirm({
        title: '重置密码',
        desc: '说明：重置后密码为mm123456',
        message: '确认要执行该操作吗？',
        confirm: () => {
          _api.marketerUserResetPassword({ marketerId: row.id, userPassword: 'mm123456'}).then(() => {
            this.$message.success('操作成功！')
          })
        }
      })
    },
  },
};
</script>