<template>
  <div class="GlobalFormDemo">
    <el-form :model="formData" inline label-width="80px" size="small" class="el-form-group_isRound">
      <el-form-item label="员工姓名" prop="marketerId">
        <el-select v-model="formData.marketerId" filterable clearable placeholder="请输入姓名查询">
          <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="角色名称" prop="roleId">
        <el-select v-model="formData.roleId" filterable clearable placeholder="请输入角色名称查询">
          <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="所属部门" prop="roleId">
        <el-select v-model="formData.marketerTerminalCode" filterable clearable placeholder="请选择所属部门查询">
          <el-option v-for="item in terminalList" :key="item.marketerTerminalCode" :label="item.marketerTerminalName" :value="item.marketerTerminalCode" />
        </el-select>
      </el-form-item>
      <el-form-item label="门店商" prop="companyId">
        <el-select v-model="formData.companyId" filterable clearable placeholder="请输入选择门店商查询">
          <el-option v-for="item in storeSelectList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="回收商" prop="merchantId">
        <el-select v-model="formData.merchantId" filterable clearable placeholder="请输入选择回收商查询">
          <el-option v-for="item in merchantSelectList" :key="item.merchantId" :label="item.merchantName" :value="item.merchantId" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" :loading="searchLoading" round @click="handleConfirm">查询</el-button>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="$refs['accountDia'].open()">添加账号</el-button>
      </el-form-item>
    </el-form>
    <AccountDia type="add" ref="accountDia" @confirm="handleConfirm()" />
  </div>
</template>

<script>
import moment from "moment";
import _api from "@/utils/request";
import AccountDia from './dialog/AccountDia.vue';
export default {
  components: { AccountDia },
  name: 'Form',
  data() {
    return {
      formData: {
        marketerId: '',
        roleId: '',
        marketerTerminalCode: '',
        companyId: '',
        merchantId: '',
      },
      roleList: [],
      userList: [],
      searchLoading: false,

      storeSelectList: [],
      merchantSelectList: [],
    }
  },
  created(){
    this.getRoleList()
    this.getUserList()
    this.getStoreSelectList()
    this.getMerchantSelectList()
    this.handleConfirm()
  },
  computed: {
    terminalList() {
      return this.$store.state.tagsView.terminalList
    },
  },
  methods: {
    getRoleList() {
      _api.marketerRoleSelectList().then(res => {
        this.roleList = res.data
      })
    },
    getUserList() {
      _api.marketerUserSelectList().then(res => {
        this.userList = res.data
      })
    },
    getStoreSelectList() {
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          this.storeSelectList = res.data;
        }
      });
    },
    getMerchantSelectList() {
      _api.getMerchantSelectList().then((res) => {
        if (res.code === 1) {
          this.merchantSelectList = res.data
        }
      })
    },
    //查询
    handleConfirm() {
      let _this = this
      this.searchLoading = true
      const SeachParams = {
        ...this.formData,
        disuseLoding: () => {
          if (typeof _this.stopSearchLoading === 'function') {
            _this.stopSearchLoading()
          }
        },
      } 
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    },
    stopSearchLoading() {
      this.searchLoading = false
    },
  }
}
</script>
